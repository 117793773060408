/* eslint-disable */
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
import { Methods as Methods0 } from './api/v1/account'
import { Methods as Methods1 } from './api/v1/account_setup_tokens'
import { Methods as Methods2 } from './api/v1/account_setup_tokens/_setup_token@string'
import { Methods as Methods3 } from './api/v1/accounts/me'
import { Methods as Methods4 } from './api/v1/accounts/me/accessible_resources/clients'
import { Methods as Methods5 } from './api/v1/accounts/me/accessible_resources/companies'
import { Methods as Methods6 } from './api/v1/accounts/me/email_change_request'
import { Methods as Methods7 } from './api/v1/accounts/me/notification_counts'
import { Methods as Methods8 } from './api/v1/accounts/me/password'
import { Methods as Methods9 } from './api/v1/accounts/me/sign_in_status'
import { Methods as Methods10 } from './api/v1/accounts/me/subscription/clients'
import { Methods as Methods11 } from './api/v1/clients/_client_id@number'
import { Methods as Methods12 } from './api/v1/clients/_client_id@number/attendance'
import { Methods as Methods13 } from './api/v1/clients/_client_id@number/attendances/fix_payment_requests'
import { Methods as Methods14 } from './api/v1/clients/_client_id@number/attendances/fix_payment_requests/_fix_payment_request_id@number'
import { Methods as Methods15 } from './api/v1/clients/_client_id@number/base'
import { Methods as Methods16 } from './api/v1/clients/_client_id@number/brand_candidates'
import { Methods as Methods17 } from './api/v1/clients/_client_id@number/brand_invitation'
import { Methods as Methods18 } from './api/v1/clients/_client_id@number/chat_rooms'
import { Methods as Methods19 } from './api/v1/clients/_client_id@number/chat_rooms/_chat_room_uid@string/messages'
import { Methods as Methods20 } from './api/v1/clients/_client_id@number/chat_rooms/_uid@string'
import { Methods as Methods21 } from './api/v1/clients/_client_id@number/chat_rooms/_uid@string/read'
import { Methods as Methods22 } from './api/v1/clients/_client_id@number/client_reviews'
import { Methods as Methods23 } from './api/v1/clients/_client_id@number/client_reviews/evaluation'
import { Methods as Methods24 } from './api/v1/clients/_client_id@number/company/np_buyer_alert'
import { Methods as Methods25 } from './api/v1/clients/_client_id@number/company_classification'
import { Methods as Methods26 } from './api/v1/clients/_client_id@number/confirmed_billing_operations/_confirmed_billing_operation_id@number/detail.pdf'
import { Methods as Methods27 } from './api/v1/clients/_client_id@number/evaluation'
import { Methods as Methods28 } from './api/v1/clients/_client_id@number/feedbacks'
import { Methods as Methods29 } from './api/v1/clients/_client_id@number/graduates'
import { Methods as Methods30 } from './api/v1/clients/_client_id@number/groups'
import { Methods as Methods31 } from './api/v1/clients/_client_id@number/groups/_group_id@number'
import { Methods as Methods32 } from './api/v1/clients/_client_id@number/groups/shared_group_summary'
import { Methods as Methods33 } from './api/v1/clients/_client_id@number/ignored_brand_candidates'
import { Methods as Methods34 } from './api/v1/clients/_client_id@number/invitation'
import { Methods as Methods35 } from './api/v1/clients/_client_id@number/offering/multidate_posting_operations'
import { Methods as Methods36 } from './api/v1/clients/_client_id@number/offering/multidate_posting_operations/_multidate_posting_operation_id@number/check'
import { Methods as Methods37 } from './api/v1/clients/_client_id@number/offerings'
import { Methods as Methods38 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number'
import { Methods as Methods39 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/close'
import { Methods as Methods40 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/closed_reason'
import { Methods as Methods41 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/multidate_posting_operations'
import { Methods as Methods42 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/onboarding/answers'
import { Methods as Methods43 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/unpublished'
import { Methods as Methods44 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users'
import { Methods as Methods45 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/_user_id@number/reject_request'
import { Methods as Methods46 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/block_status'
import { Methods as Methods47 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users/unreviewed'
import { Methods as Methods48 } from './api/v1/clients/_client_id@number/offerings/_offering_id@number/users.csv'
import { Methods as Methods49 } from './api/v1/clients/_client_id@number/offerings/calendar'
import { Methods as Methods50 } from './api/v1/clients/_client_id@number/offerings/unreviewed'
import { Methods as Methods51 } from './api/v1/clients/_client_id@number/offerings/wage'
import { Methods as Methods52 } from './api/v1/clients/_client_id@number/offers'
import { Methods as Methods53 } from './api/v1/clients/_client_id@number/offers/_offer_id@number'
import { Methods as Methods54 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/archive'
import { Methods as Methods55 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings'
import { Methods as Methods56 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/_offering_id@number'
import { Methods as Methods57 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/_offering_id@number/validate'
import { Methods as Methods58 } from './api/v1/clients/_client_id@number/offers/_offer_id@number/offerings/validate'
import { Methods as Methods59 } from './api/v1/clients/_client_id@number/offers/suggestion'
import { Methods as Methods60 } from './api/v1/clients/_client_id@number/phone_number/verification'
import { Methods as Methods61 } from './api/v1/clients/_client_id@number/restriction'
import { Methods as Methods62 } from './api/v1/clients/_client_id@number/statements'
import { Methods as Methods63 } from './api/v1/clients/_client_id@number/statements/details.csv'
import { Methods as Methods64 } from './api/v1/clients/_client_id@number/statements/next_billing'
import { Methods as Methods65 } from './api/v1/clients/_client_id@number/tracking_data'
import { Methods as Methods66 } from './api/v1/clients/_client_id@number/user_block_lists'
import { Methods as Methods67 } from './api/v1/clients/_client_id@number/users/_user_id@number'
import { Methods as Methods68 } from './api/v1/clients/_client_id@number/users/_user_id@number/badges'
import { Methods as Methods69 } from './api/v1/clients/_client_id@number/users/_user_id@number/emergency_contact'
import { Methods as Methods70 } from './api/v1/clients/_client_id@number/users/_user_id@number/graduates'
import { Methods as Methods71 } from './api/v1/clients/_client_id@number/users/_user_id@number/groups'
import { Methods as Methods72 } from './api/v1/clients/_client_id@number/users/_user_id@number/offerings'
import { Methods as Methods73 } from './api/v1/clients/_client_id@number/users/_user_id@number/reviews_from_client'
import { Methods as Methods74 } from './api/v1/companies/_company_id@number'
import { Methods as Methods75 } from './api/v1/companies/_company_id@number/base'
import { Methods as Methods76 } from './api/v1/companies/_company_id@number/clients'
import { Methods as Methods77 } from './api/v1/companies/_company_id@number/confirmed_billing_operations/_confirmed_billing_operation_id@number/detail.pdf'
import { Methods as Methods78 } from './api/v1/companies/_company_id@number/statements'
import { Methods as Methods79 } from './api/v1/companies/_company_id@number/statements/billing_adjustments.csv'
import { Methods as Methods80 } from './api/v1/companies/_company_id@number/statements/billing_transfer_commissions.csv'
import { Methods as Methods81 } from './api/v1/companies/_company_id@number/statements/client_statements.csv'
import { Methods as Methods82 } from './api/v1/companies/_company_id@number/statements/company_statements.csv'
import { Methods as Methods83 } from './api/v1/companies/_company_id@number/statements/details'
import { Methods as Methods84 } from './api/v1/companies/_company_id@number/statements/details/by_client'
import { Methods as Methods85 } from './api/v1/companies/_company_id@number/statements/details/of_client.csv'
import { Methods as Methods86 } from './api/v1/companies/_company_id@number/worker_list_storages'
import { Methods as Methods87 } from './api/v1/companies/_company_id@number/worker_list_storages/_worker_list_storage_id@number'
import { Methods as Methods88 } from './api/v1/master_company_occupations'
import { Methods as Methods89 } from './api/v1/notices'
import { Methods as Methods90 } from './api/v1/notices/_notice_id@number/read'
import { Methods as Methods91 } from './api/v1/occupations'
import { Methods as Methods92 } from './api/v1/sign_in'
import { Methods as Methods93 } from './api/v1/sign_in/confirm'
import { Methods as Methods94 } from './api/v1/sign_in/refresh'
import { Methods as Methods95 } from './api/v1/sign_out'
import { Methods as Methods96 } from './api/v1/tags'
import { Methods as Methods97 } from './api/v2/clients/_client_id@number/offerings/_offering_id@number/users/reviews'
import { Methods as Methods98 } from './api/v2/clients/_client_id@number/users'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/app/api/v1/account'
  const PATH1 = '/app/api/v1/account_setup_tokens'
  const PATH2 = '/app/api/v1/accounts/me'
  const PATH3 = '/app/api/v1/accounts/me/accessible_resources/clients'
  const PATH4 = '/app/api/v1/accounts/me/accessible_resources/companies'
  const PATH5 = '/app/api/v1/accounts/me/email_change_request'
  const PATH6 = '/app/api/v1/accounts/me/notification_counts'
  const PATH7 = '/app/api/v1/accounts/me/password'
  const PATH8 = '/app/api/v1/accounts/me/sign_in_status'
  const PATH9 = '/app/api/v1/accounts/me/subscription/clients'
  const PATH10 = '/app/api/v1/clients'
  const PATH11 = '/attendance'
  const PATH12 = '/attendances/fix_payment_requests'
  const PATH13 = '/base'
  const PATH14 = '/brand_candidates'
  const PATH15 = '/brand_invitation'
  const PATH16 = '/chat_rooms'
  const PATH17 = '/messages'
  const PATH18 = '/read'
  const PATH19 = '/client_reviews'
  const PATH20 = '/client_reviews/evaluation'
  const PATH21 = '/company/np_buyer_alert'
  const PATH22 = '/company_classification'
  const PATH23 = '/confirmed_billing_operations'
  const PATH24 = '/detail.pdf'
  const PATH25 = '/evaluation'
  const PATH26 = '/feedbacks'
  const PATH27 = '/graduates'
  const PATH28 = '/groups'
  const PATH29 = '/groups/shared_group_summary'
  const PATH30 = '/ignored_brand_candidates'
  const PATH31 = '/invitation'
  const PATH32 = '/offering/multidate_posting_operations'
  const PATH33 = '/check'
  const PATH34 = '/offerings'
  const PATH35 = '/close'
  const PATH36 = '/closed_reason'
  const PATH37 = '/multidate_posting_operations'
  const PATH38 = '/onboarding/answers'
  const PATH39 = '/unpublished'
  const PATH40 = '/users'
  const PATH41 = '/reject_request'
  const PATH42 = '/users/block_status'
  const PATH43 = '/users/unreviewed'
  const PATH44 = '/users.csv'
  const PATH45 = '/offerings/calendar'
  const PATH46 = '/offerings/unreviewed'
  const PATH47 = '/offerings/wage'
  const PATH48 = '/offers'
  const PATH49 = '/archive'
  const PATH50 = '/validate'
  const PATH51 = '/offerings/validate'
  const PATH52 = '/offers/suggestion'
  const PATH53 = '/phone_number/verification'
  const PATH54 = '/restriction'
  const PATH55 = '/statements'
  const PATH56 = '/statements/details.csv'
  const PATH57 = '/statements/next_billing'
  const PATH58 = '/tracking_data'
  const PATH59 = '/user_block_lists'
  const PATH60 = '/badges'
  const PATH61 = '/emergency_contact'
  const PATH62 = '/reviews_from_client'
  const PATH63 = '/app/api/v1/companies'
  const PATH64 = '/clients'
  const PATH65 = '/statements/billing_adjustments.csv'
  const PATH66 = '/statements/billing_transfer_commissions.csv'
  const PATH67 = '/statements/client_statements.csv'
  const PATH68 = '/statements/company_statements.csv'
  const PATH69 = '/statements/details'
  const PATH70 = '/statements/details/by_client'
  const PATH71 = '/statements/details/of_client.csv'
  const PATH72 = '/worker_list_storages'
  const PATH73 = '/app/api/v1/master_company_occupations'
  const PATH74 = '/app/api/v1/notices'
  const PATH75 = '/app/api/v1/occupations'
  const PATH76 = '/app/api/v1/sign_in'
  const PATH77 = '/app/api/v1/sign_in/confirm'
  const PATH78 = '/app/api/v1/sign_in/refresh'
  const PATH79 = '/app/api/v1/sign_out'
  const PATH80 = '/app/api/v1/tags'
  const PATH81 = '/app/api/v2/clients'
  const PATH82 = '/users/reviews'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    api: {
      v1: {
        account: {
          get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
          $get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
            fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`
        },
        account_setup_tokens: {
          _setup_token: (val0: string) => {
            const prefix0 = `${PATH1}/${val0}`

            return {
              get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json(),
              $get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, prefix0, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send(),
              $patch: (option: { body: Methods2['patch']['reqBody'], headers?: Methods2['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods2['patch']['status']>(prefix, prefix0, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix0}`
            }
          },
          post: (option: { body: Methods1['post']['reqBody'], headers?: Methods1['post']['reqHeaders'], config?: T }) =>
            fetch<void, BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).send(),
          $post: (option: { body: Methods1['post']['reqBody'], headers?: Methods1['post']['reqHeaders'], config?: T }) =>
            fetch<void, BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH1}`
        },
        accounts: {
          me: {
            accessible_resources: {
              clients: {
                get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json(),
                $get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH3}`
              },
              companies: {
                get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json(),
                $get: (option?: { headers?: Methods5['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH4}`
              }
            },
            email_change_request: {
              post: (option: { body: Methods6['post']['reqBody'], headers?: Methods6['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send(),
              $post: (option: { body: Methods6['post']['reqBody'], headers?: Methods6['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['post']['status']>(prefix, PATH5, POST, option).send().then(r => r.body),
              patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, PATH5, PATCH, option).send(),
              $patch: (option: { body: Methods6['patch']['reqBody'], headers?: Methods6['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods6['patch']['status']>(prefix, PATH5, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH5}`
            },
            notification_counts: {
              get: (option?: { headers?: Methods7['get']['reqHeaders'], config?: T }) =>
                fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json(),
              $get: (option?: { headers?: Methods7['get']['reqHeaders'], config?: T }) =>
                fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH6}`
            },
            password: {
              patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, PATH7, PATCH, option).send(),
              $patch: (option: { body: Methods8['patch']['reqBody'], headers?: Methods8['patch']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods8['patch']['status']>(prefix, PATH7, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH7}`
            },
            sign_in_status: {
              get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json(),
              $get: (option?: { headers?: Methods9['get']['reqHeaders'], config?: T }) =>
                fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH8}`
            },
            subscription: {
              clients: {
                get: (option?: { headers?: Methods10['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).json(),
                $get: (option?: { headers?: Methods10['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
                put: (option: { body: Methods10['put']['reqBody'], headers?: Methods10['put']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods10['put']['status']>(prefix, PATH9, PUT, option).send(),
                $put: (option: { body: Methods10['put']['reqBody'], headers?: Methods10['put']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods10['put']['status']>(prefix, PATH9, PUT, option).send().then(r => r.body),
                $path: () => `${prefix}${PATH9}`
              }
            },
            get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
              fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).json(),
            $get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
              fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
            patch: (option: { body: Methods3['patch']['reqBody'], headers?: Methods3['patch']['reqHeaders'], config?: T }) =>
              fetch<void, BasicHeaders, Methods3['patch']['status']>(prefix, PATH2, PATCH, option).send(),
            $patch: (option: { body: Methods3['patch']['reqBody'], headers?: Methods3['patch']['reqHeaders'], config?: T }) =>
              fetch<void, BasicHeaders, Methods3['patch']['status']>(prefix, PATH2, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH2}`
          }
        },
        clients: {
          _client_id: (val1: number) => {
            const prefix1 = `${PATH10}/${val1}`

            return {
              attendance: {
                get: (option?: { headers?: Methods12['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, `${prefix1}${PATH11}`, GET, option).json(),
                $get: (option?: { headers?: Methods12['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, `${prefix1}${PATH11}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods12['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['post']['resBody'], BasicHeaders, Methods12['post']['status']>(prefix, `${prefix1}${PATH11}`, POST, option).json(),
                $post: (option?: { headers?: Methods12['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods12['post']['resBody'], BasicHeaders, Methods12['post']['status']>(prefix, `${prefix1}${PATH11}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH11}`
              },
              attendances: {
                fix_payment_requests: {
                  _fix_payment_request_id: (val2: number) => {
                    const prefix2 = `${prefix1}${PATH12}/${val2}`

                    return {
                      patch: (option: { body: Methods14['patch']['reqBody'], headers?: Methods14['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods14['patch']['status']>(prefix, prefix2, PATCH, option).send(),
                      $patch: (option: { body: Methods14['patch']['reqBody'], headers?: Methods14['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods14['patch']['status']>(prefix, prefix2, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix2}`
                    }
                  },
                  get: (option: { query: Methods13['get']['query'], headers?: Methods13['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, `${prefix1}${PATH12}`, GET, option).json(),
                  $get: (option: { query: Methods13['get']['query'], headers?: Methods13['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, `${prefix1}${PATH12}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods13['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                }
              },
              base: {
                get: (option?: { headers?: Methods15['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix1}${PATH13}`, GET, option).json(),
                $get: (option?: { headers?: Methods15['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, `${prefix1}${PATH13}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH13}`
              },
              brand_candidates: {
                get: (option: { query: Methods16['get']['query'], headers?: Methods16['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, `${prefix1}${PATH14}`, GET, option).json(),
                $get: (option: { query: Methods16['get']['query'], headers?: Methods16['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, `${prefix1}${PATH14}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods16['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              brand_invitation: {
                get: (option?: { headers?: Methods17['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, `${prefix1}${PATH15}`, GET, option).json(),
                $get: (option?: { headers?: Methods17['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, `${prefix1}${PATH15}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods17['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, `${prefix1}${PATH15}`, POST, option).json(),
                $post: (option?: { headers?: Methods17['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, `${prefix1}${PATH15}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH15}`
              },
              chat_rooms: {
                _chat_room_uid: (val3: string) => {
                  const prefix3 = `${prefix1}${PATH16}/${val3}`

                  return {
                    messages: {
                      post: (option: { body: Methods19['post']['reqBody'], headers?: Methods19['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods19['post']['status']>(prefix, `${prefix3}${PATH17}`, POST, option).send(),
                      $post: (option: { body: Methods19['post']['reqBody'], headers?: Methods19['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods19['post']['status']>(prefix, `${prefix3}${PATH17}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix3}${PATH17}`
                    }
                  }
                },
                _uid: (val4: string) => {
                  const prefix4 = `${prefix1}${PATH16}/${val4}`

                  return {
                    read: {
                      post: (option?: { headers?: Methods21['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods21['post']['status']>(prefix, `${prefix4}${PATH18}`, POST, option).send(),
                      $post: (option?: { headers?: Methods21['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods21['post']['status']>(prefix, `${prefix4}${PATH18}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix4}${PATH18}`
                    },
                    get: (option?: { headers?: Methods20['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, prefix4, GET, option).json(),
                    $get: (option?: { headers?: Methods20['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}`
                  }
                },
                get: (option: { query: Methods18['get']['query'], headers?: Methods18['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, `${prefix1}${PATH16}`, GET, option).json(),
                $get: (option: { query: Methods18['get']['query'], headers?: Methods18['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, `${prefix1}${PATH16}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods18['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              client_reviews: {
                evaluation: {
                  get: (option?: { headers?: Methods23['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, `${prefix1}${PATH20}`, GET, option).json(),
                  $get: (option?: { headers?: Methods23['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, `${prefix1}${PATH20}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH20}`
                },
                get: (option: { query: Methods22['get']['query'], headers?: Methods22['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, `${prefix1}${PATH19}`, GET, option).json(),
                $get: (option: { query: Methods22['get']['query'], headers?: Methods22['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, `${prefix1}${PATH19}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods22['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              company: {
                np_buyer_alert: {
                  get: (option?: { headers?: Methods24['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix1}${PATH21}`, GET, option).json(),
                  $get: (option?: { headers?: Methods24['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix1}${PATH21}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH21}`
                }
              },
              company_classification: {
                get: (option?: { headers?: Methods25['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, `${prefix1}${PATH22}`, GET, option).json(),
                $get: (option?: { headers?: Methods25['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, `${prefix1}${PATH22}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH22}`
              },
              confirmed_billing_operations: {
                _confirmed_billing_operation_id: (val5: number) => {
                  const prefix5 = `${prefix1}${PATH23}/${val5}`

                  return {
                    detail_pdf: {
                      get: (option?: { headers?: Methods26['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods26['get']['resHeaders'], Methods26['get']['status']>(prefix, `${prefix5}${PATH24}`, GET, option).send(),
                      $get: (option?: { headers?: Methods26['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods26['get']['resHeaders'], Methods26['get']['status']>(prefix, `${prefix5}${PATH24}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix5}${PATH24}`
                    }
                  }
                }
              },
              evaluation: {
                get: (option?: { headers?: Methods27['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix1}${PATH25}`, GET, option).json(),
                $get: (option?: { headers?: Methods27['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, `${prefix1}${PATH25}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH25}`
              },
              feedbacks: {
                post: (option: { body: Methods28['post']['reqBody'], headers?: Methods28['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods28['post']['status']>(prefix, `${prefix1}${PATH26}`, POST, option).send(),
                $post: (option: { body: Methods28['post']['reqBody'], headers?: Methods28['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods28['post']['status']>(prefix, `${prefix1}${PATH26}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH26}`
              },
              graduates: {
                post: (option: { body: Methods29['post']['reqBody'], headers?: Methods29['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods29['post']['status']>(prefix, `${prefix1}${PATH27}`, POST, option).send(),
                $post: (option: { body: Methods29['post']['reqBody'], headers?: Methods29['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods29['post']['status']>(prefix, `${prefix1}${PATH27}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH27}`
              },
              groups: {
                _group_id: (val6: number) => {
                  const prefix6 = `${prefix1}${PATH28}/${val6}`

                  return {
                    get: (option?: { headers?: Methods31['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, prefix6, GET, option).json(),
                    $get: (option?: { headers?: Methods31['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                    patch: (option: { body: Methods31['patch']['reqBody'], headers?: Methods31['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods31['patch']['resBody'], BasicHeaders, Methods31['patch']['status']>(prefix, prefix6, PATCH, option).json(),
                    $patch: (option: { body: Methods31['patch']['reqBody'], headers?: Methods31['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods31['patch']['resBody'], BasicHeaders, Methods31['patch']['status']>(prefix, prefix6, PATCH, option).json().then(r => r.body),
                    delete: (option?: { query?: Methods31['delete']['query'], headers?: Methods31['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods31['delete']['status']>(prefix, prefix6, DELETE, option).send(),
                    $delete: (option?: { query?: Methods31['delete']['query'], headers?: Methods31['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods31['delete']['status']>(prefix, prefix6, DELETE, option).send().then(r => r.body),
                    $path: (option?: { method: 'delete'; query: Methods31['delete']['query'] }) =>
                      `${prefix}${prefix6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  }
                },
                shared_group_summary: {
                  get: (option?: { headers?: Methods32['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix1}${PATH29}`, GET, option).json(),
                  $get: (option?: { headers?: Methods32['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix1}${PATH29}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH29}`
                },
                get: (option?: { query?: Methods30['get']['query'], headers?: Methods30['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix1}${PATH28}`, GET, option).json(),
                $get: (option?: { query?: Methods30['get']['query'], headers?: Methods30['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix1}${PATH28}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods30['post']['reqBody'], headers?: Methods30['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix1}${PATH28}`, POST, option).json(),
                $post: (option: { body: Methods30['post']['reqBody'], headers?: Methods30['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix1}${PATH28}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods30['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              ignored_brand_candidates: {
                post: (option: { body: Methods33['post']['reqBody'], headers?: Methods33['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods33['post']['status']>(prefix, `${prefix1}${PATH30}`, POST, option).send(),
                $post: (option: { body: Methods33['post']['reqBody'], headers?: Methods33['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods33['post']['status']>(prefix, `${prefix1}${PATH30}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH30}`
              },
              invitation: {
                get: (option?: { headers?: Methods34['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, `${prefix1}${PATH31}`, GET, option).json(),
                $get: (option?: { headers?: Methods34['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, `${prefix1}${PATH31}`, GET, option).json().then(r => r.body),
                post: (option?: { headers?: Methods34['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods34['post']['resBody'], BasicHeaders, Methods34['post']['status']>(prefix, `${prefix1}${PATH31}`, POST, option).json(),
                $post: (option?: { headers?: Methods34['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods34['post']['resBody'], BasicHeaders, Methods34['post']['status']>(prefix, `${prefix1}${PATH31}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH31}`
              },
              offering: {
                multidate_posting_operations: {
                  _multidate_posting_operation_id: (val7: number) => {
                    const prefix7 = `${prefix1}${PATH32}/${val7}`

                    return {
                      check: {
                        patch: (option?: { headers?: Methods36['patch']['reqHeaders'], config?: T }) =>
                          fetch<Methods36['patch']['resBody'], BasicHeaders, Methods36['patch']['status']>(prefix, `${prefix7}${PATH33}`, PATCH, option).json(),
                        $patch: (option?: { headers?: Methods36['patch']['reqHeaders'], config?: T }) =>
                          fetch<Methods36['patch']['resBody'], BasicHeaders, Methods36['patch']['status']>(prefix, `${prefix7}${PATH33}`, PATCH, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix7}${PATH33}`
                      }
                    }
                  },
                  get: (option?: { headers?: Methods35['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods35['get']['resBody'], BasicHeaders, Methods35['get']['status']>(prefix, `${prefix1}${PATH32}`, GET, option).json(),
                  $get: (option?: { headers?: Methods35['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods35['get']['resBody'], BasicHeaders, Methods35['get']['status']>(prefix, `${prefix1}${PATH32}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH32}`
                }
              },
              offerings: {
                _offering_id: (val8: number) => {
                  const prefix8 = `${prefix1}${PATH34}/${val8}`

                  return {
                    close: {
                      patch: (option?: { headers?: Methods39['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods39['patch']['status']>(prefix, `${prefix8}${PATH35}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods39['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods39['patch']['status']>(prefix, `${prefix8}${PATH35}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH35}`
                    },
                    closed_reason: {
                      post: (option: { body: Methods40['post']['reqBody'], headers?: Methods40['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods40['post']['status']>(prefix, `${prefix8}${PATH36}`, POST, option).send(),
                      $post: (option: { body: Methods40['post']['reqBody'], headers?: Methods40['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods40['post']['status']>(prefix, `${prefix8}${PATH36}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH36}`
                    },
                    multidate_posting_operations: {
                      post: (option: { body: Methods41['post']['reqBody'], headers?: Methods41['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods41['post']['status']>(prefix, `${prefix8}${PATH37}`, POST, option).send(),
                      $post: (option: { body: Methods41['post']['reqBody'], headers?: Methods41['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods41['post']['status']>(prefix, `${prefix8}${PATH37}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH37}`
                    },
                    onboarding: {
                      answers: {
                        get: (option?: { headers?: Methods42['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix8}${PATH38}`, GET, option).json(),
                        $get: (option?: { headers?: Methods42['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, `${prefix8}${PATH38}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix8}${PATH38}`
                      }
                    },
                    unpublished: {
                      patch: (option?: { headers?: Methods43['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods43['patch']['status']>(prefix, `${prefix8}${PATH39}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods43['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods43['patch']['status']>(prefix, `${prefix8}${PATH39}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH39}`
                    },
                    users: {
                      _user_id: (val9: number) => {
                        const prefix9 = `${prefix8}${PATH40}/${val9}`

                        return {
                          reject_request: {
                            post: (option: { body: Methods45['post']['reqBody'], headers?: Methods45['post']['reqHeaders'], config?: T }) =>
                              fetch<Methods45['post']['resBody'], BasicHeaders, Methods45['post']['status']>(prefix, `${prefix9}${PATH41}`, POST, option).json(),
                            $post: (option: { body: Methods45['post']['reqBody'], headers?: Methods45['post']['reqHeaders'], config?: T }) =>
                              fetch<Methods45['post']['resBody'], BasicHeaders, Methods45['post']['status']>(prefix, `${prefix9}${PATH41}`, POST, option).json().then(r => r.body),
                            $path: () => `${prefix}${prefix9}${PATH41}`
                          }
                        }
                      },
                      block_status: {
                        get: (option: { query: Methods46['get']['query'], headers?: Methods46['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, `${prefix8}${PATH42}`, GET, option).json(),
                        $get: (option: { query: Methods46['get']['query'], headers?: Methods46['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, `${prefix8}${PATH42}`, GET, option).json().then(r => r.body),
                        $path: (option?: { method?: 'get'; query: Methods46['get']['query'] }) =>
                          `${prefix}${prefix8}${PATH42}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                      },
                      unreviewed: {
                        get: (option?: { headers?: Methods47['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, `${prefix8}${PATH43}`, GET, option).json(),
                        $get: (option?: { headers?: Methods47['get']['reqHeaders'], config?: T }) =>
                          fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, `${prefix8}${PATH43}`, GET, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix8}${PATH43}`
                      },
                      get: (option?: { headers?: Methods44['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix8}${PATH40}`, GET, option).json(),
                      $get: (option?: { headers?: Methods44['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix8}${PATH40}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH40}`
                    },
                    users_csv: {
                      get: (option?: { headers?: Methods48['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods48['get']['resHeaders'], Methods48['get']['status']>(prefix, `${prefix8}${PATH44}`, GET, option).send(),
                      $get: (option?: { headers?: Methods48['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods48['get']['resHeaders'], Methods48['get']['status']>(prefix, `${prefix8}${PATH44}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix8}${PATH44}`
                    },
                    get: (option?: { headers?: Methods38['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, prefix8, GET, option).json(),
                    $get: (option?: { headers?: Methods38['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix8}`
                  }
                },
                calendar: {
                  get: (option: { query: Methods49['get']['query'], headers?: Methods49['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix1}${PATH45}`, GET, option).json(),
                  $get: (option: { query: Methods49['get']['query'], headers?: Methods49['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix1}${PATH45}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods49['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH45}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                unreviewed: {
                  get: (option?: { headers?: Methods50['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, `${prefix1}${PATH46}`, GET, option).json(),
                  $get: (option?: { headers?: Methods50['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, `${prefix1}${PATH46}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH46}`
                },
                wage: {
                  get: (option: { query: Methods51['get']['query'], headers?: Methods51['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods51['get']['resBody'], BasicHeaders, Methods51['get']['status']>(prefix, `${prefix1}${PATH47}`, GET, option).json(),
                  $get: (option: { query: Methods51['get']['query'], headers?: Methods51['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods51['get']['resBody'], BasicHeaders, Methods51['get']['status']>(prefix, `${prefix1}${PATH47}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods51['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH47}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods37['get']['query'], headers?: Methods37['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, `${prefix1}${PATH34}`, GET, option).json(),
                $get: (option: { query: Methods37['get']['query'], headers?: Methods37['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, `${prefix1}${PATH34}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods37['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              offers: {
                _offer_id: (val10: number) => {
                  const prefix10 = `${prefix1}${PATH48}/${val10}`

                  return {
                    archive: {
                      patch: (option?: { headers?: Methods54['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods54['patch']['status']>(prefix, `${prefix10}${PATH49}`, PATCH, option).send(),
                      $patch: (option?: { headers?: Methods54['patch']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods54['patch']['status']>(prefix, `${prefix10}${PATH49}`, PATCH, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix10}${PATH49}`
                    },
                    offerings: {
                      _offering_id: (val11: number) => {
                        const prefix11 = `${prefix10}${PATH34}/${val11}`

                        return {
                          validate: {
                            post: (option: { body: Methods57['post']['reqBody'], headers?: Methods57['post']['reqHeaders'], config?: T }) =>
                              fetch<void, BasicHeaders, Methods57['post']['status']>(prefix, `${prefix11}${PATH50}`, POST, option).send(),
                            $post: (option: { body: Methods57['post']['reqBody'], headers?: Methods57['post']['reqHeaders'], config?: T }) =>
                              fetch<void, BasicHeaders, Methods57['post']['status']>(prefix, `${prefix11}${PATH50}`, POST, option).send().then(r => r.body),
                            $path: () => `${prefix}${prefix11}${PATH50}`
                          },
                          patch: (option: { body: Methods56['patch']['reqBody'], headers?: Methods56['patch']['reqHeaders'], config?: T }) =>
                            fetch<Methods56['patch']['resBody'], BasicHeaders, Methods56['patch']['status']>(prefix, prefix11, PATCH, option).json(),
                          $patch: (option: { body: Methods56['patch']['reqBody'], headers?: Methods56['patch']['reqHeaders'], config?: T }) =>
                            fetch<Methods56['patch']['resBody'], BasicHeaders, Methods56['patch']['status']>(prefix, prefix11, PATCH, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix11}`
                        }
                      },
                      validate: {
                        post: (option: { body: Methods58['post']['reqBody'], headers?: Methods58['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods58['post']['status']>(prefix, `${prefix10}${PATH51}`, POST, option).send(),
                        $post: (option: { body: Methods58['post']['reqBody'], headers?: Methods58['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods58['post']['status']>(prefix, `${prefix10}${PATH51}`, POST, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix10}${PATH51}`
                      },
                      get: (option: { query: Methods55['get']['query'], headers?: Methods55['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix10}${PATH34}`, GET, option).json(),
                      $get: (option: { query: Methods55['get']['query'], headers?: Methods55['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix10}${PATH34}`, GET, option).json().then(r => r.body),
                      post: (option: { body: Methods55['post']['reqBody'], headers?: Methods55['post']['reqHeaders'], config?: T }) =>
                        fetch<Methods55['post']['resBody'], BasicHeaders, Methods55['post']['status']>(prefix, `${prefix10}${PATH34}`, POST, option).json(),
                      $post: (option: { body: Methods55['post']['reqBody'], headers?: Methods55['post']['reqHeaders'], config?: T }) =>
                        fetch<Methods55['post']['resBody'], BasicHeaders, Methods55['post']['status']>(prefix, `${prefix10}${PATH34}`, POST, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods55['get']['query'] }) =>
                        `${prefix}${prefix10}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { headers?: Methods53['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, prefix10, GET, option).json(),
                    $get: (option?: { headers?: Methods53['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, prefix10, GET, option).json().then(r => r.body),
                    patch: (option: { body: Methods53['patch']['reqBody'], headers?: Methods53['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods53['patch']['resBody'], BasicHeaders, Methods53['patch']['status']>(prefix, prefix10, PATCH, option).json(),
                    $patch: (option: { body: Methods53['patch']['reqBody'], headers?: Methods53['patch']['reqHeaders'], config?: T }) =>
                      fetch<Methods53['patch']['resBody'], BasicHeaders, Methods53['patch']['status']>(prefix, prefix10, PATCH, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix10}`
                  }
                },
                suggestion: {
                  get: (option: { query: Methods59['get']['query'], headers?: Methods59['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix1}${PATH52}`, GET, option).json(),
                  $get: (option: { query: Methods59['get']['query'], headers?: Methods59['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix1}${PATH52}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods59['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH52}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods52['get']['query'], headers?: Methods52['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix1}${PATH48}`, GET, option).json(),
                $get: (option: { query: Methods52['get']['query'], headers?: Methods52['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix1}${PATH48}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods52['post']['reqBody'], headers?: Methods52['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix1}${PATH48}`, POST, option).json(),
                $post: (option: { body: Methods52['post']['reqBody'], headers?: Methods52['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix1}${PATH48}`, POST, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods52['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH48}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              phone_number: {
                verification: {
                  post: (option: { body: Methods60['post']['reqBody'], headers?: Methods60['post']['reqHeaders'], config?: T }) =>
                    fetch<Methods60['post']['resBody'], BasicHeaders, Methods60['post']['status']>(prefix, `${prefix1}${PATH53}`, POST, option).json(),
                  $post: (option: { body: Methods60['post']['reqBody'], headers?: Methods60['post']['reqHeaders'], config?: T }) =>
                    fetch<Methods60['post']['resBody'], BasicHeaders, Methods60['post']['status']>(prefix, `${prefix1}${PATH53}`, POST, option).json().then(r => r.body),
                  patch: (option: { body: Methods60['patch']['reqBody'], headers?: Methods60['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods60['patch']['status']>(prefix, `${prefix1}${PATH53}`, PATCH, option).send(),
                  $patch: (option: { body: Methods60['patch']['reqBody'], headers?: Methods60['patch']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods60['patch']['status']>(prefix, `${prefix1}${PATH53}`, PATCH, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH53}`
                }
              },
              restriction: {
                get: (option?: { headers?: Methods61['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, `${prefix1}${PATH54}`, GET, option).json(),
                $get: (option?: { headers?: Methods61['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, `${prefix1}${PATH54}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH54}`
              },
              statements: {
                details_csv: {
                  get: (option?: { query?: Methods63['get']['query'], headers?: Methods63['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods63['get']['resHeaders'], Methods63['get']['status']>(prefix, `${prefix1}${PATH56}`, GET, option).send(),
                  $get: (option?: { query?: Methods63['get']['query'], headers?: Methods63['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods63['get']['resHeaders'], Methods63['get']['status']>(prefix, `${prefix1}${PATH56}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods63['get']['query'] }) =>
                    `${prefix}${prefix1}${PATH56}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                next_billing: {
                  get: (option?: { headers?: Methods64['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix1}${PATH57}`, GET, option).json(),
                  $get: (option?: { headers?: Methods64['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix1}${PATH57}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix1}${PATH57}`
                },
                get: (option: { query: Methods62['get']['query'], headers?: Methods62['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix1}${PATH55}`, GET, option).json(),
                $get: (option: { query: Methods62['get']['query'], headers?: Methods62['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix1}${PATH55}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods62['get']['query'] }) =>
                  `${prefix}${prefix1}${PATH55}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              tracking_data: {
                get: (option?: { headers?: Methods65['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, `${prefix1}${PATH58}`, GET, option).json(),
                $get: (option?: { headers?: Methods65['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, `${prefix1}${PATH58}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix1}${PATH58}`
              },
              user_block_lists: {
                post: (option: { body: Methods66['post']['reqBody'], headers?: Methods66['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods66['post']['resBody'], BasicHeaders, Methods66['post']['status']>(prefix, `${prefix1}${PATH59}`, POST, option).json(),
                $post: (option: { body: Methods66['post']['reqBody'], headers?: Methods66['post']['reqHeaders'], config?: T }) =>
                  fetch<Methods66['post']['resBody'], BasicHeaders, Methods66['post']['status']>(prefix, `${prefix1}${PATH59}`, POST, option).json().then(r => r.body),
                delete: (option: { query: Methods66['delete']['query'], headers?: Methods66['delete']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods66['delete']['status']>(prefix, `${prefix1}${PATH59}`, DELETE, option).send(),
                $delete: (option: { query: Methods66['delete']['query'], headers?: Methods66['delete']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods66['delete']['status']>(prefix, `${prefix1}${PATH59}`, DELETE, option).send().then(r => r.body),
                $path: (option?: { method: 'delete'; query: Methods66['delete']['query'] }) =>
                  `${prefix}${prefix1}${PATH59}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              users: {
                _user_id: (val12: number) => {
                  const prefix12 = `${prefix1}${PATH40}/${val12}`

                  return {
                    badges: {
                      get: (option?: { headers?: Methods68['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix12}${PATH60}`, GET, option).json(),
                      $get: (option?: { headers?: Methods68['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix12}${PATH60}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH60}`
                    },
                    emergency_contact: {
                      get: (option?: { headers?: Methods69['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix12}${PATH61}`, GET, option).json(),
                      $get: (option?: { headers?: Methods69['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix12}${PATH61}`, GET, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH61}`
                    },
                    graduates: {
                      post: (option?: { headers?: Methods70['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods70['post']['status']>(prefix, `${prefix12}${PATH27}`, POST, option).send(),
                      $post: (option?: { headers?: Methods70['post']['reqHeaders'], config?: T }) =>
                        fetch<void, BasicHeaders, Methods70['post']['status']>(prefix, `${prefix12}${PATH27}`, POST, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH27}`
                    },
                    groups: {
                      put: (option: { body: Methods71['put']['reqBody'], headers?: Methods71['put']['reqHeaders'], config?: T }) =>
                        fetch<Methods71['put']['resBody'], BasicHeaders, Methods71['put']['status']>(prefix, `${prefix12}${PATH28}`, PUT, option).json(),
                      $put: (option: { body: Methods71['put']['reqBody'], headers?: Methods71['put']['reqHeaders'], config?: T }) =>
                        fetch<Methods71['put']['resBody'], BasicHeaders, Methods71['put']['status']>(prefix, `${prefix12}${PATH28}`, PUT, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix12}${PATH28}`
                    },
                    offerings: {
                      get: (option: { query: Methods72['get']['query'], headers?: Methods72['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods72['get']['resBody'], BasicHeaders, Methods72['get']['status']>(prefix, `${prefix12}${PATH34}`, GET, option).json(),
                      $get: (option: { query: Methods72['get']['query'], headers?: Methods72['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods72['get']['resBody'], BasicHeaders, Methods72['get']['status']>(prefix, `${prefix12}${PATH34}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods72['get']['query'] }) =>
                        `${prefix}${prefix12}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    reviews_from_client: {
                      get: (option: { query: Methods73['get']['query'], headers?: Methods73['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, `${prefix12}${PATH62}`, GET, option).json(),
                      $get: (option: { query: Methods73['get']['query'], headers?: Methods73['get']['reqHeaders'], config?: T }) =>
                        fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, `${prefix12}${PATH62}`, GET, option).json().then(r => r.body),
                      $path: (option?: { method?: 'get'; query: Methods73['get']['query'] }) =>
                        `${prefix}${prefix12}${PATH62}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                    },
                    get: (option?: { headers?: Methods67['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, prefix12, GET, option).json(),
                    $get: (option?: { headers?: Methods67['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, prefix12, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix12}`
                  }
                }
              },
              get: (option?: { headers?: Methods11['get']['reqHeaders'], config?: T }) =>
                fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, prefix1, GET, option).json(),
              $get: (option?: { headers?: Methods11['get']['reqHeaders'], config?: T }) =>
                fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods11['patch']['reqBody'], headers?: Methods11['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods11['patch']['resBody'], BasicHeaders, Methods11['patch']['status']>(prefix, prefix1, PATCH, option).json(),
              $patch: (option: { body: Methods11['patch']['reqBody'], headers?: Methods11['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods11['patch']['resBody'], BasicHeaders, Methods11['patch']['status']>(prefix, prefix1, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}`
            }
          }
        },
        companies: {
          _company_id: (val13: number) => {
            const prefix13 = `${PATH63}/${val13}`

            return {
              base: {
                get: (option?: { headers?: Methods75['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, `${prefix13}${PATH13}`, GET, option).json(),
                $get: (option?: { headers?: Methods75['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, `${prefix13}${PATH13}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH13}`
              },
              clients: {
                get: (option?: { headers?: Methods76['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods76['get']['resBody'], BasicHeaders, Methods76['get']['status']>(prefix, `${prefix13}${PATH64}`, GET, option).json(),
                $get: (option?: { headers?: Methods76['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods76['get']['resBody'], BasicHeaders, Methods76['get']['status']>(prefix, `${prefix13}${PATH64}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH64}`
              },
              confirmed_billing_operations: {
                _confirmed_billing_operation_id: (val14: number) => {
                  const prefix14 = `${prefix13}${PATH23}/${val14}`

                  return {
                    detail_pdf: {
                      get: (option?: { headers?: Methods77['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods77['get']['resHeaders'], Methods77['get']['status']>(prefix, `${prefix14}${PATH24}`, GET, option).send(),
                      $get: (option?: { headers?: Methods77['get']['reqHeaders'], config?: T }) =>
                        fetch<void, Methods77['get']['resHeaders'], Methods77['get']['status']>(prefix, `${prefix14}${PATH24}`, GET, option).send().then(r => r.body),
                      $path: () => `${prefix}${prefix14}${PATH24}`
                    }
                  }
                }
              },
              statements: {
                billing_adjustments_csv: {
                  get: (option?: { query?: Methods79['get']['query'], headers?: Methods79['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods79['get']['resHeaders'], Methods79['get']['status']>(prefix, `${prefix13}${PATH65}`, GET, option).send(),
                  $get: (option?: { query?: Methods79['get']['query'], headers?: Methods79['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods79['get']['resHeaders'], Methods79['get']['status']>(prefix, `${prefix13}${PATH65}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods79['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH65}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                billing_transfer_commissions_csv: {
                  get: (option?: { query?: Methods80['get']['query'], headers?: Methods80['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods80['get']['resHeaders'], Methods80['get']['status']>(prefix, `${prefix13}${PATH66}`, GET, option).send(),
                  $get: (option?: { query?: Methods80['get']['query'], headers?: Methods80['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods80['get']['resHeaders'], Methods80['get']['status']>(prefix, `${prefix13}${PATH66}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods80['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH66}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                client_statements_csv: {
                  get: (option?: { query?: Methods81['get']['query'], headers?: Methods81['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods81['get']['resHeaders'], Methods81['get']['status']>(prefix, `${prefix13}${PATH67}`, GET, option).send(),
                  $get: (option?: { query?: Methods81['get']['query'], headers?: Methods81['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods81['get']['resHeaders'], Methods81['get']['status']>(prefix, `${prefix13}${PATH67}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods81['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH67}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                company_statements_csv: {
                  get: (option?: { query?: Methods82['get']['query'], headers?: Methods82['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods82['get']['resHeaders'], Methods82['get']['status']>(prefix, `${prefix13}${PATH68}`, GET, option).send(),
                  $get: (option?: { query?: Methods82['get']['query'], headers?: Methods82['get']['reqHeaders'], config?: T }) =>
                    fetch<void, Methods82['get']['resHeaders'], Methods82['get']['status']>(prefix, `${prefix13}${PATH68}`, GET, option).send().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods82['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH68}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                details: {
                  by_client: {
                    get: (option: { query: Methods84['get']['query'], headers?: Methods84['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods84['get']['resBody'], BasicHeaders, Methods84['get']['status']>(prefix, `${prefix13}${PATH70}`, GET, option).json(),
                    $get: (option: { query: Methods84['get']['query'], headers?: Methods84['get']['reqHeaders'], config?: T }) =>
                      fetch<Methods84['get']['resBody'], BasicHeaders, Methods84['get']['status']>(prefix, `${prefix13}${PATH70}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods84['get']['query'] }) =>
                      `${prefix}${prefix13}${PATH70}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  of_client_csv: {
                    get: (option?: { query?: Methods85['get']['query'], headers?: Methods85['get']['reqHeaders'], config?: T }) =>
                      fetch<void, Methods85['get']['resHeaders'], Methods85['get']['status']>(prefix, `${prefix13}${PATH71}`, GET, option).send(),
                    $get: (option?: { query?: Methods85['get']['query'], headers?: Methods85['get']['reqHeaders'], config?: T }) =>
                      fetch<void, Methods85['get']['resHeaders'], Methods85['get']['status']>(prefix, `${prefix13}${PATH71}`, GET, option).send().then(r => r.body),
                    $path: (option?: { method?: 'get'; query: Methods85['get']['query'] }) =>
                      `${prefix}${prefix13}${PATH71}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                  },
                  get: (option?: { query?: Methods83['get']['query'], headers?: Methods83['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods83['get']['resBody'], BasicHeaders, Methods83['get']['status']>(prefix, `${prefix13}${PATH69}`, GET, option).json(),
                  $get: (option?: { query?: Methods83['get']['query'], headers?: Methods83['get']['reqHeaders'], config?: T }) =>
                    fetch<Methods83['get']['resBody'], BasicHeaders, Methods83['get']['status']>(prefix, `${prefix13}${PATH69}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get'; query: Methods83['get']['query'] }) =>
                    `${prefix}${prefix13}${PATH69}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
                },
                get: (option: { query: Methods78['get']['query'], headers?: Methods78['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods78['get']['resBody'], BasicHeaders, Methods78['get']['status']>(prefix, `${prefix13}${PATH55}`, GET, option).json(),
                $get: (option: { query: Methods78['get']['query'], headers?: Methods78['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods78['get']['resBody'], BasicHeaders, Methods78['get']['status']>(prefix, `${prefix13}${PATH55}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods78['get']['query'] }) =>
                  `${prefix}${prefix13}${PATH55}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              },
              worker_list_storages: {
                _worker_list_storage_id: (val15: number) => {
                  const prefix15 = `${prefix13}${PATH72}/${val15}`

                  return {
                    delete: (option?: { headers?: Methods87['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods87['delete']['status']>(prefix, prefix15, DELETE, option).send(),
                    $delete: (option?: { headers?: Methods87['delete']['reqHeaders'], config?: T }) =>
                      fetch<void, BasicHeaders, Methods87['delete']['status']>(prefix, prefix15, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix15}`
                  }
                },
                get: (option?: { headers?: Methods86['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods86['get']['resBody'], BasicHeaders, Methods86['get']['status']>(prefix, `${prefix13}${PATH72}`, GET, option).json(),
                $get: (option?: { headers?: Methods86['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods86['get']['resBody'], BasicHeaders, Methods86['get']['status']>(prefix, `${prefix13}${PATH72}`, GET, option).json().then(r => r.body),
                post: (option: { body: Methods86['post']['reqBody'], headers?: Methods86['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods86['post']['status']>(prefix, `${prefix13}${PATH72}`, POST, option).send(),
                $post: (option: { body: Methods86['post']['reqBody'], headers?: Methods86['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods86['post']['status']>(prefix, `${prefix13}${PATH72}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix13}${PATH72}`
              },
              get: (option?: { headers?: Methods74['get']['reqHeaders'], config?: T }) =>
                fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, prefix13, GET, option).json(),
              $get: (option?: { headers?: Methods74['get']['reqHeaders'], config?: T }) =>
                fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, prefix13, GET, option).json().then(r => r.body),
              patch: (option: { body: Methods74['patch']['reqBody'], headers?: Methods74['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods74['patch']['resBody'], BasicHeaders, Methods74['patch']['status']>(prefix, prefix13, PATCH, option).json(),
              $patch: (option: { body: Methods74['patch']['reqBody'], headers?: Methods74['patch']['reqHeaders'], config?: T }) =>
                fetch<Methods74['patch']['resBody'], BasicHeaders, Methods74['patch']['status']>(prefix, prefix13, PATCH, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix13}`
            }
          }
        },
        master_company_occupations: {
          get: (option?: { headers?: Methods88['get']['reqHeaders'], config?: T }) =>
            fetch<Methods88['get']['resBody'], BasicHeaders, Methods88['get']['status']>(prefix, PATH73, GET, option).json(),
          $get: (option?: { headers?: Methods88['get']['reqHeaders'], config?: T }) =>
            fetch<Methods88['get']['resBody'], BasicHeaders, Methods88['get']['status']>(prefix, PATH73, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH73}`
        },
        notices: {
          _notice_id: (val16: number) => {
            const prefix16 = `${PATH74}/${val16}`

            return {
              read: {
                post: (option?: { headers?: Methods90['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods90['post']['status']>(prefix, `${prefix16}${PATH18}`, POST, option).send(),
                $post: (option?: { headers?: Methods90['post']['reqHeaders'], config?: T }) =>
                  fetch<void, BasicHeaders, Methods90['post']['status']>(prefix, `${prefix16}${PATH18}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix16}${PATH18}`
              }
            }
          },
          get: (option: { query: Methods89['get']['query'], headers?: Methods89['get']['reqHeaders'], config?: T }) =>
            fetch<Methods89['get']['resBody'], BasicHeaders, Methods89['get']['status']>(prefix, PATH74, GET, option).json(),
          $get: (option: { query: Methods89['get']['query'], headers?: Methods89['get']['reqHeaders'], config?: T }) =>
            fetch<Methods89['get']['resBody'], BasicHeaders, Methods89['get']['status']>(prefix, PATH74, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods89['get']['query'] }) =>
            `${prefix}${PATH74}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        occupations: {
          get: (option?: { headers?: Methods91['get']['reqHeaders'], config?: T }) =>
            fetch<Methods91['get']['resBody'], BasicHeaders, Methods91['get']['status']>(prefix, PATH75, GET, option).json(),
          $get: (option?: { headers?: Methods91['get']['reqHeaders'], config?: T }) =>
            fetch<Methods91['get']['resBody'], BasicHeaders, Methods91['get']['status']>(prefix, PATH75, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH75}`
        },
        sign_in: {
          confirm: {
            get: (option?: { headers?: Methods93['get']['reqHeaders'], config?: T }) =>
              fetch<Methods93['get']['resBody'], BasicHeaders, Methods93['get']['status']>(prefix, PATH77, GET, option).json(),
            $get: (option?: { headers?: Methods93['get']['reqHeaders'], config?: T }) =>
              fetch<Methods93['get']['resBody'], BasicHeaders, Methods93['get']['status']>(prefix, PATH77, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH77}`
          },
          refresh: {
            patch: (option?: { headers?: Methods94['patch']['reqHeaders'], config?: T }) =>
              fetch<void, Methods94['patch']['resHeaders'], Methods94['patch']['status']>(prefix, PATH78, PATCH, option).send(),
            $patch: (option?: { headers?: Methods94['patch']['reqHeaders'], config?: T }) =>
              fetch<void, Methods94['patch']['resHeaders'], Methods94['patch']['status']>(prefix, PATH78, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${PATH78}`
          },
          post: (option: { body: Methods92['post']['reqBody'], headers?: Methods92['post']['reqHeaders'], config?: T }) =>
            fetch<void, Methods92['post']['resHeaders'], Methods92['post']['status']>(prefix, PATH76, POST, option).send(),
          $post: (option: { body: Methods92['post']['reqBody'], headers?: Methods92['post']['reqHeaders'], config?: T }) =>
            fetch<void, Methods92['post']['resHeaders'], Methods92['post']['status']>(prefix, PATH76, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH76}`
        },
        sign_out: {
          delete: (option?: { headers?: Methods95['delete']['reqHeaders'], config?: T }) =>
            fetch<Methods95['delete']['resBody'], Methods95['delete']['resHeaders'], Methods95['delete']['status']>(prefix, PATH79, DELETE, option).json(),
          $delete: (option?: { headers?: Methods95['delete']['reqHeaders'], config?: T }) =>
            fetch<Methods95['delete']['resBody'], Methods95['delete']['resHeaders'], Methods95['delete']['status']>(prefix, PATH79, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH79}`
        },
        tags: {
          get: (option?: { headers?: Methods96['get']['reqHeaders'], config?: T }) =>
            fetch<Methods96['get']['resBody'], BasicHeaders, Methods96['get']['status']>(prefix, PATH80, GET, option).json(),
          $get: (option?: { headers?: Methods96['get']['reqHeaders'], config?: T }) =>
            fetch<Methods96['get']['resBody'], BasicHeaders, Methods96['get']['status']>(prefix, PATH80, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH80}`
        }
      },
      v2: {
        clients: {
          _client_id: (val17: number) => {
            const prefix17 = `${PATH81}/${val17}`

            return {
              offerings: {
                _offering_id: (val18: number) => {
                  const prefix18 = `${prefix17}${PATH34}/${val18}`

                  return {
                    users: {
                      reviews: {
                        post: (option: { body: Methods97['post']['reqBody'], headers?: Methods97['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods97['post']['status']>(prefix, `${prefix18}${PATH82}`, POST, option).send(),
                        $post: (option: { body: Methods97['post']['reqBody'], headers?: Methods97['post']['reqHeaders'], config?: T }) =>
                          fetch<void, BasicHeaders, Methods97['post']['status']>(prefix, `${prefix18}${PATH82}`, POST, option).send().then(r => r.body),
                        $path: () => `${prefix}${prefix18}${PATH82}`
                      }
                    }
                  }
                }
              },
              users: {
                get: (option?: { query?: Methods98['get']['query'], headers?: Methods98['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods98['get']['resBody'], BasicHeaders, Methods98['get']['status']>(prefix, `${prefix17}${PATH40}`, GET, option).json(),
                $get: (option?: { query?: Methods98['get']['query'], headers?: Methods98['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods98['get']['resBody'], BasicHeaders, Methods98['get']['status']>(prefix, `${prefix17}${PATH40}`, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get'; query: Methods98['get']['query'] }) =>
                  `${prefix}${prefix17}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
              }
            }
          }
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
