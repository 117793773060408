import axiosClient from '@aspida/axios';
import { AxiosRequestConfig } from 'axios';
import api from '~/apis/AutogenTimeeApiClient/app/$api';
import { configuredAxiosClient } from './configuredAxiosClient';

export const aspidaClient = api(axiosClient(configuredAxiosClient()));

export const aspidaClientConfigurable = (axiosConfig: AxiosRequestConfig) => {
  return api(axiosClient(configuredAxiosClient(), axiosConfig));
};
