import { AxiosError } from 'axios';
import { isAxiosError } from './isAxiosError';

type MessageOfError = { error: string } | { errors: string[] };

export const isErrorWithMessage = (error: unknown): error is AxiosError<MessageOfError> =>
  isAxiosError(error) &&
  error.response?.data &&
  ('error' in error.response.data || 'errors' in error.response.data);

export const messagesOfMessageError = (error: AxiosError<MessageOfError>) =>
  error.response?.data
    ? 'error' in error.response.data
      ? [error.response.data.error]
      : 'errors' in error.response.data
        ? error.response.data.errors
        : [] // AxiosError<MessageOfError> のレスポンスには error, errors のいずれか一方は含まれるので到達し得ない
    : []; // AxiosError<MessageOfError> には response.data が含まれるので到達し得ない
