import { useGTM } from '~/thirdParty/tracking/GTM';

// NOTE: loginとlogoutの両方をここで表現する
export const useKarteLoginEvent = () => {
  const { sendToGTM } = useGTM();
  const sendKarteLoginEvent = (loginStatus: boolean) => {
    sendToGTM({ event: 'ACCOUNT_CHANGE_LOGIN_STATUS', status: loginStatus });
  };

  return sendKarteLoginEvent;
};
