import React from 'react';
import { LayoutSingleAction, Props as LayoutProps } from '~/components/layouts/LayoutSingleAction';
import { LayoutComponent } from '~/types/layout';

export const getAuthLayout = (args: {
  getLayoutContents: () => Omit<LayoutProps, 'children'>;
}): LayoutComponent => {
  const LayoutComponent: LayoutComponent = (props) => {
    // NOTE:
    // 他のカテゴリではuseCategoryContextのsetCategoryContextを設定していますが, このカテゴリでは設定していません。
    // 理由は以下のとおりです。
    // - pageContextで担っているAPIクライアントの共通部分(logout)の設計がこのカテゴリと合っていない。(authカテゴリではログアウトは行わないため)
    // - 上記の共通部分の処理はuseErrorHandler内のAPIエラー時の共通処理として使用されるが、ログアウトの必要がないので設定の必要がない。

    return <LayoutSingleAction {...args.getLayoutContents()}>{props.children}</LayoutSingleAction>;
  };
  return LayoutComponent;
};
