export const fixedPhrase = {
  updateSuccess: '正常に更新を行いました。',
  reviewSuccess: '正常にレビューを送信しました。',
  networkError: 'ネットワーク接続がありません。ネットワーク環境を確認後、再度お試しください。',
  serverError: 'サーバーでエラーが発生しました。しばらく時間をおいてから再度お試しください。',
  notFoundError:
    '対象が見つからないか、閲覧の権限がありません。このメッセージをクリックしてログインページから適当なアカウントにログインし再度お試しください。',
  inputRequired: '入力が必須な項目です。',
  beforeUnload: '行った変更が保存されません。ページを離脱してよろしいですか？',
  unknownError: '不明なエラーが発生しました',
} as const;
