import { useState } from 'react';
import { NextRouter } from 'next/router';
import { envManager } from '~/constants/envManager';
import { globalPathBuilder } from '~/constants/globalPathBuilder';
import { useKarteLoginEvent } from '~/hooks/tracking/useKarteLoginEvent';
import { PageReplaceNextLink, RedirectUrl } from '~/models/types/common';

export type ApiClientWithLogout = {
  logout: () => Promise<RedirectUrl | PageReplaceNextLink>;
};

type IUseLogout = (
  apiClient: ApiClientWithLogout,
  router: NextRouter,
) => {
  state: {
    processing: boolean;
  };
  actions: {
    logout: () => void;
  };
};

export const useLogout: IUseLogout = (apiClient, router) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const sendKarteLoginEvent = useKarteLoginEvent();

  return {
    state: {
      processing,
    },
    actions: {
      logout: async () => {
        setProcessing(true);
        try {
          const res = await apiClient.logout();
          if ('redirectUrl' in res) {
            location.replace(res.redirectUrl);
          } else {
            router.push(res.pageReplaceNextLink);
          }
          sendKarteLoginEvent(false);
        } catch (_error) {
          // TODO: responseを使うようにする
          if (envManager.publicEnv.targetDomain === 'client') {
            location.replace(globalPathBuilder.login);
          }
        } finally {
          setProcessing(false);
        }
      },
    },
  };
};
