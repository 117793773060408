import { NextRouter, useRouter } from 'next/router';
import { authApiClient, IAuthApiClient } from '~/categories/auth/apis/authApiClient/index';
import { useToastMessages, Option } from '~/hooks/context/useToastMessages';
import { MessageHandler } from '~/types/message';

export type AuthPageContext = {
  router: NextRouter;
  apiClient: IAuthApiClient;
  showMessage: MessageHandler<Option>;
};

export const useAuthPageContext = (): AuthPageContext => {
  const router = useRouter();
  const client = authApiClient;
  const toastHooks = useToastMessages();

  return {
    router,
    apiClient: client,
    showMessage: toastHooks.actions.pushMessage,
  };
};
